import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import theme from "../../styles/theme"
import { MQ_DESKTOP, columns } from "../../styles"

const Container = styled.div`
  white-space: nowrap;
  overflow-x: scroll;
  padding-bottom: 80px;

  &::-webkit-scrollbar {
    background-color: ${theme.COLOR_DIVIDER};
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.PHOTO_SCROLLBAR_COLOR};
    height: 2px;
  }

  ${MQ_DESKTOP} {
    padding-bottom: ${columns(1)};
  }
`

const ImageWrapper = styled.div`
  display: inline-block;
  width: ${({ width }) => width}px;

  &:not(:last-child) {
    margin-right: 20px;

    ${MQ_DESKTOP} {
      margin-right: ${columns(0.25)};
    }
  }
`

const Image = styled(Img)`
  max-height: ${({ maxHeight }) => maxHeight}px;
`

const PhotoSectionPhotos = () => {
  const data = useStaticQuery(graphql`
    query PastEventPhotos {
      markdownRemark(
        fileAbsolutePath: { regex: "//_content/pages/index.md$/" }
      ) {
        id
        frontmatter {
          photos {
            photo {
              childImageSharp {
                id
                fluid(maxHeight: 280) {
                  presentationHeight
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const photos = data.markdownRemark.frontmatter.photos.map(
    ({
      photo: {
        childImageSharp: { id, fluid },
      },
    }) => (
      <ImageWrapper key={id} width={fluid.presentationWidth}>
        <Image
          imgStyle={{ objectFit: "contain" }}
          maxHeight={fluid.presentationHeight}
          fluid={fluid}
        />
      </ImageWrapper>
    )
  )

  return <Container>{photos}</Container>
}

export default PhotoSectionPhotos
