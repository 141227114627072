import React from "react"
import styled from "@emotion/styled"
import {
  MQ_DESKTOP,
  columns,
  SectionContainer,
  Text,
  StringValue,
} from "../../styles"
import theme from "../../styles/theme"
import PhotoSectionPhotos from "./photo-section-photos"

const Header = styled.div`
  margin-bottom: 42px;

  ${MQ_DESKTOP} {
    margin-bottom: ${columns(0.5)};
  }
`

export const TabbedText = styled.p`
  margin-left: 22px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const PhotoSection = () => {
  return (
    <SectionContainer>
      <Header>
        <p>
          <Text color={theme.COLOR_OPERATOR}>if </Text>
          <Text color={theme.COLOR_SYMBOL}>{`(`}</Text>
          pics
          <Text color={theme.COLOR_SYMBOL}>{`) {`}</Text>
        </p>
        <TabbedText>
          <Text color={theme.COLOR_OPERATOR}>return </Text>
          event
        </TabbedText>
        <p>
          <Text color={theme.COLOR_SYMBOL}>{`} `}</Text>
          <Text color={theme.COLOR_OPERATOR}>else</Text>
          <Text color={theme.COLOR_SYMBOL}>{` {`}</Text>
        </p>
        <TabbedText>
          <Text color={theme.COLOR_OPERATOR}>return </Text>
          <StringValue>It didn't happen</StringValue>
        </TabbedText>
        <p>
          <Text color={theme.COLOR_SYMBOL}>{`}`}</Text>
        </p>
      </Header>
      <PhotoSectionPhotos />
    </SectionContainer>
  )
}

export default PhotoSection
