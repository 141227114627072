import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Moment from "moment"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Jumbotron from "../components/home/jumbotron"
import NextEvent from "../components/home/next-event"
import PhotoSection from "../components/home/photo-setion"
import Contacts from "../components/home/contacts"
import theme from "../styles/theme"
import PreviousEvents from "../components/home/previous-events"
import Sponsors from "../components/home/sponsors"

const Divider = styled.hr`
  border-color: ${theme.COLOR_DIVIDER};
  border-top: none;
`

const LandingPage = ({ data }) => {
  const { seoTitle, title, description, groupMeetupUrl } = data.page.frontmatter

  const mappedEvents = data.meetupGroup.events.map(
    ({ id, name, link, local_date }) => ({
      id,
      name,
      link,
      date: new Moment(local_date),
    })
  )

  return (
    <Layout>
      <SEO title={seoTitle} />
      <Jumbotron
        comments={[`${title} site`, "", "TODO: Make the logo bigger"]}
        title={title}
        tagline={description}
        groupUrl={groupMeetupUrl}
      />
      <Divider />
      <NextEvent
      // attendeeCount={null} TODO: Pull from Meetup
      />
      <Divider />
      <PhotoSection />
      <PreviousEvents events={mappedEvents} />
      <Divider />
      <Contacts />
      <Divider />
      <Sponsors />
    </Layout>
  )
}

export default LandingPage

export const query = graphql`
  query NodeJSOC {
    page: markdownRemark(fileAbsolutePath: { regex: "//pages/index.md$/" }) {
      frontmatter {
        seoTitle
        title
        description
        groupMeetupUrl
      }
    }
    meetupGroup {
      events {
        id
        name
        local_date
        link
      }
    }
  }
`
