import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Moment from "moment"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import {
  MQ_DESKTOP,
  columns,
  SectionContainer,
  NumValue,
  stringStyle,
} from "../../styles"
import theme from "../../styles/theme"
import { CommentHeader } from "../header"

const Event = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 50px;
  }

  ${MQ_DESKTOP} {
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: ${columns(0.5)};
    }
  }
`

const Date = styled(NumValue)`
  margin-bottom: 15px;
  font-size: ${theme.FONT_SIZE_XS};

  ${MQ_DESKTOP} {
    width: ${columns(2)};
    font-size: ${theme.FONT_SIZE_M};
  }
`

const Name = styled.p`
  font-weight: normal;
  margin-bottom: 15px;
  font-size: ${theme.FONT_SIZE_S};
  ${stringStyle}

  ${MQ_DESKTOP} {
    font-size: ${theme.FONT_SIZE_M};
  }
`

const PreviousEvents = ({ events }) => {
  return (
    <SectionContainer>
      <CommentHeader>Previous Events</CommentHeader>
      {events.map(({ id, date, name, link }) => (
        <Event key={id}>
          <Date>{date.format("M.D.YY")}</Date>
          <OutboundLink href={link} target="_blank" rel="noopener noreferrer">
            <Name>{name.trim()}</Name>
          </OutboundLink>
        </Event>
      ))}
    </SectionContainer>
  )
}

PreviousEvents.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.instanceOf(Moment).isRequired,
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
}

export default PreviousEvents
