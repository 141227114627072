import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { SectionContainer } from "../../styles"
import { CommentHeader } from "../header"
import Contact from "./contact"

const Contacts = () => {
  const data = useStaticQuery(graphql`
    query Contacts {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//_content/contacts/.+.md$/" } }
      ) {
        edges {
          node {
            frontmatter {
              name
              email
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const contacts = data.allMarkdownRemark.edges.map(
    ({ node: { frontmatter } }) => {
      const { name, email, image } = frontmatter
      const {
        childImageSharp: { fluid },
      } = image
      return <Contact key={name} fluid={fluid} email={email} />
    }
  )

  return (
    <SectionContainer>
      <CommentHeader>Contact</CommentHeader>
      {contacts}
    </SectionContainer>
  )
}

export default Contacts
