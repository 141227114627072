import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { MQ_DESKTOP, columns, greyButtonStyle } from "../../styles"
import SpeakerInfoModal from "./speaker-info-modal"

const Button = styled.button`
  ${greyButtonStyle}
  padding: 20px;
  margin-bottom: 20px;

  &:before {
    transform: translateX(-20px);
  }

  ${MQ_DESKTOP} {
    margin-bottom: 0;
    max-width: none;

    &:not(:last-child) {
      margin-right: ${columns(0.5)};
    }
  }
`

const ImageContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 32px;
  margin-right: 30px;
  overflow: hidden;
  flex-shrink: 0;

  ${MQ_DESKTOP} {
    width: 64px;
    height: 64px;
    margin-right: ${columns(0.25)};
  }
`

const Image = styled(Img)`
  min-height: 100%;
`

const Name = styled.div`
  margin-right: 10px;

  ${MQ_DESKTOP} {
    margin-right: ${columns(0.25)};
  }
`

const Speaker = ({ name, info, fluid }) => {
  const [showModal, setShowModal] = useState(false)
  const speakerImage = <Image fluid={fluid} />
  const closeModal = useCallback(() => setShowModal(false), [])

  return (
    <>
      <Button onClick={() => setShowModal(true)}>
        <ImageContainer>{speakerImage}</ImageContainer>
        <Name>{name}</Name>
      </Button>
      <SpeakerInfoModal
        show={showModal}
        closeModal={closeModal}
        image={speakerImage}
        name={name}
        info={info}
      />
    </>
  )
}

Speaker.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  fluid: PropTypes.any.isRequired,
}

export default Speaker
