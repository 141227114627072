import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Moment from "moment"
import { MQ_DESKTOP, columns, NumValue } from "../../styles"

const Container = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 47px;
  }

  ${MQ_DESKTOP} {
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: ${columns(0.25)};
    }
  }
`

const Time = styled.div`
  margin-bottom: 2px;
  flex-shrink: 0;

  ${MQ_DESKTOP} {
    margin-bottom: 0;
    width: ${columns(2)};
  }
`

const AgendaItem = ({ time, subject }) => {
  const moment = new Moment(time)

  return (
    <Container>
      <Time>
        <NumValue>{moment.format("h:mm")}</NumValue>
        {` `}
        {moment.format("A")}
      </Time>
      <span>{subject}</span>
    </Container>
  )
}

AgendaItem.propTypes = {
  time: PropTypes.string.isRequired,
  subject: PropTypes.string,
}

export default AgendaItem
