import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { columns, MQ_DESKTOP } from "../../styles"
import theme from "../../styles/theme"
import Modal from "../modal"

const ImageContainer = styled.div`
  flex-shrink: 0;
  background-color: white;
  overflow: hidden;

  ${MQ_DESKTOP} {
    height: ${columns(3.5)};
    width: ${columns(3.5)};
    margin-right: ${columns(0.75)};
  }
`

const TextContainer = styled.div`
  ${MQ_DESKTOP} {
    min-width: ${columns(5.25)};
  }
`

const Name = styled.h6`
  font-size: ${theme.FONT_SIZE_L};
  margin-bottom: 30px;
  color: ${theme.MODAL_HEADER_TEXT};

  ${MQ_DESKTOP} {
    margin-bottom: ${columns(0.5)};
  }
`

const Info = styled.div`
  line-height: 24px;

  ${MQ_DESKTOP} {
    line-height: 32px;
  }
`

const SpeakerInfoModal = ({ image, name, info, ...props }) => {
  return (
    <Modal {...props}>
      <ImageContainer>{image}</ImageContainer>
      <TextContainer>
        <Name>{name}</Name>
        <Info>{info}</Info>
      </TextContainer>
    </Modal>
  )
}

SpeakerInfoModal.propTypes = {
  image: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
}

export default SpeakerInfoModal
