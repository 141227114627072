import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Moment from "moment"
import { useStaticQuery, graphql } from "gatsby"
import Button from "../button"
import {
  MQ_DESKTOP,
  columns,
  SectionContainer,
  stringStyle,
  NumValue,
} from "../../styles"
import theme from "../../styles/theme"
import { CommentHeader, CommentSubHeader } from "../header"
import AgendaItem from "./agenda-item"
import Speaker from "./speaker"

const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 52px;

  ${MQ_DESKTOP} {
    flex-direction: row;
    margin-bottom: ${columns(0.5)};
  }
`

const EventDate = styled.span`
  display: inline-block;
  font-size: ${theme.FONT_SIZE_L};
  text-transform: uppercase;
  margin-bottom: 18px;

  ${MQ_DESKTOP} {
    width: ${columns(2)};
    font-size: ${theme.FONT_SIZE_L};
    margin-bottom: 0;
  }
`

const Name = styled.h3`
  display: inline-block;
  font-size: ${theme.FONT_SIZE_L};
  font-weight: normal;
  ${stringStyle}

  ${MQ_DESKTOP} {
    font-size: ${theme.FONT_SIZE_XL};
  }
`

const Location = styled.div`
  margin-bottom: 60px;
  ${stringStyle}

  ${MQ_DESKTOP} {
    margin-bottom: ${columns(0.6)};
  }
`

const Agenda = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  ${MQ_DESKTOP} {
    margin-bottom: ${columns(0.6)};
  }
`

const Speakers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;

  ${MQ_DESKTOP} {
    flex-direction: row;
    margin-bottom: ${columns(0.6)};
  }
`

const MoreInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > :not(:last-child) {
    margin-right: 30px;

    ${MQ_DESKTOP} {
      margin-right: ${columns(0.75)};
    }
  }
`

const NextEvent = ({ attendeeCount }) => {
  const data = useStaticQuery(graphql`
    query NextEvent {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//_content/events/.+.md$/" } }
        limit: 1
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              name
              date
              location
              address
              eventMeetupUrl
              agenda {
                subject
                time
              }
              speakers {
                name
                info
                image {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [event] = data.allMarkdownRemark.edges
  if (!event) return null
  const {
    name,
    date,
    location,
    address,
    eventMeetupUrl,
    agenda,
    speakers,
  } = event.node.frontmatter
  const eventDate = new Moment(date)

  const agendaItems = agenda.map(({ time, subject }) => (
    <AgendaItem key={time} time={time} subject={subject} />
  ))

  const speakerButtons = speakers.map(({ name, info, image }) => {
    const { fluid } = image.childImageSharp
    return <Speaker key={name} name={name} info={info} fluid={fluid} />
  })

  return (
    <SectionContainer>
      <CommentHeader>Next Event</CommentHeader>
      <Title>
        <EventDate>
          {eventDate.format("MMM")}
          <NumValue>
            {` `}
            {eventDate.format("D")}
          </NumValue>
        </EventDate>
        <Name>{name}</Name>
      </Title>
      <CommentSubHeader>Agenda</CommentSubHeader>
      <Agenda>{agendaItems}</Agenda>
      <CommentSubHeader>Speakers</CommentSubHeader>
      <Speakers>{speakerButtons}</Speakers>
      <CommentSubHeader>Location</CommentSubHeader>
      <Location>{`${location}: ${address}`}</Location>
      <MoreInfo>
        <Button linkTo={eventMeetupUrl}>View on Meetup</Button>
        {attendeeCount && (
          <span>
            <NumValue>{attendeeCount}</NumValue>
            {` `}Attending
          </span>
        )}
      </MoreInfo>
    </SectionContainer>
  )
}

NextEvent.propTypes = {
  attendeeCount: PropTypes.number,
}

export default NextEvent
