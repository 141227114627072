import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import {
  MQ_DESKTOP,
  columns,
  SectionContainer,
  greyButtonStyle,
} from "../../styles"
import { CommentHeader } from "../header"

const Sponsor = styled(OutboundLink)`
  ${greyButtonStyle}
  width: calc(50vw - 30px);
  height: calc(50vw - 30px);
  max-width: 180px;
  max-height: 180px;
  margin-right: 20px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  ${MQ_DESKTOP} {
    width: ${columns(2)};
    height: ${columns(2)};
    max-width: ${columns(2)};
    max-height: ${columns(2)};
    margin-right: ${columns(0.5)};
  }
`

const SponsorsContainer = styled.div`
  display: flex;
`

const Logo = styled(Img)`
  width: 50%;
`

const Sponsors = () => {
  const data = useStaticQuery(graphql`
    query Sponsors {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//_content/sponsors/.+.md$/" } }
      ) {
        edges {
          node {
            frontmatter {
              url
              name
              logo {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const sponsors = data.allMarkdownRemark.edges.map(
    ({ node: { frontmatter } }) => {
      const { url, name, logo } = frontmatter
      const {
        childImageSharp: { fluid },
      } = logo
      return (
        <Sponsor key={name} href={url} target="_blank">
          <Logo fluid={fluid} />
        </Sponsor>
      )
    }
  )

  return (
    <SectionContainer>
      <CommentHeader>Sponsors</CommentHeader>
      <SponsorsContainer>{sponsors}</SponsorsContainer>
    </SectionContainer>
  )
}

export default Sponsors
