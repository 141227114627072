import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import theme from "../styles/theme"

const StyledButton = styled(OutboundLink)`
  display: inline-block;
  text-align: center;
  font-family: ${theme.FONT_FAMILY};
  background: ${theme.BUTTON_BACKGROUND};
  color: ${theme.BUTTON_TEXT};
  padding: 15px 30px;
  border-radius: 8px;
`

const Button = ({ children, linkTo, ...props }) => {
  return (
    <StyledButton
      href={linkTo}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  linkTo: PropTypes.string,
  blank: PropTypes.bool,
}

export default Button
