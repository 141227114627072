import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { columns, MQ_DESKTOP, NO_SCROLL } from "../styles"
import theme from "../styles/theme"

const ModalBackground = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background-color: ${theme.MODAL_CONTAINER_BACKGROUND};
  padding: 20px;

  ${MQ_DESKTOP} {
    padding: ${columns(1.5)};
  }
`

const ModalContainer = styled.div`
  position: relative;
  background-color: ${theme.MODAL_BACKGROUND};
  padding: 50px 30px;
  color: ${theme.MODAL_TEXT};

  ${MQ_DESKTOP} {
    padding: ${columns(0.75)};
    max-width: ${columns(10)};
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 30px;
  width: 30px;
  background-color: transparent;
  color: ${theme.MODAL_CLOSE_BUTTON_COLOR};
  font-size: ${theme.FONT_SIZE_XS};

  &:after {
    content: "x";
  }
`

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${MQ_DESKTOP} {
    flex-direction: row;
  }
`

const Modal = ({ show, closeModal, children }) => {
  // Register escape key listener
  // Perform cleanup on escape key listener and modal elements
  useEffect(() => {
    if (typeof document === `undefined`) return
    const handleEsc = ({ keyCode }) => keyCode === 27 && closeModal()
    window.addEventListener("keydown", handleEsc)
    const portal = document.getElementById("portal")
    const { firstElementChild: modal } = portal

    return () => {
      window.removeEventListener("keydown", handleEsc)
      if (modal && modal.parentNode === portal) portal.removeChild(modal)
    }
  }, [closeModal])

  // Freeze background when modal is open, unfreeze when closed
  const scrollY = typeof window !== `undefined` && window.scrollY
  useEffect(() => {
    if (typeof document === `undefined`) return
    const [body] = document.getElementsByTagName("body")
    if (show) {
      body.classList.add(NO_SCROLL)
      body.style.marginTop = `-${scrollY}px`
    }
    return () => {
      if (show) {
        body.classList.remove(NO_SCROLL)
        body.style.marginTop = 0
        if (scrollY && typeof window !== `undefined`)
          window.scrollTo(0, scrollY)
      }
    }
  }, [show, scrollY])

  // Close the modal if the user clicks anywhere in the background
  const handleBackgroundClick = ({ target }) => {
    if (typeof document === `undefined`) return
    const { firstElementChild: modalBackground } = document.getElementById(
      "portal"
    )
    if (target === modalBackground) closeModal()
  }

  const portalContent = show && (
    <ModalBackground onClick={handleBackgroundClick}>
      <ModalContainer>
        <CloseButton onClick={closeModal} />
        <ModalContent>{children}</ModalContent>
      </ModalContainer>
    </ModalBackground>
  )

  return (
    typeof document !== `undefined` &&
    ReactDOM.createPortal(portalContent, document.getElementById("portal"))
  )
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default Modal
