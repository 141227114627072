import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Button from "../button"
import { MQ_DESKTOP, columns, stringStyle, Comment } from "../../styles"
import theme from "../../styles/theme"

const Container = styled.div`
  padding-top: 50px;
  padding-bottom: 100px;
`

const Title = styled.h1`
  font-size: ${theme.FONT_SIZE_XL};
  font-weight: normal;
  margin-top: 60px;
  margin-bottom: 60px;

  ${MQ_DESKTOP} {
    font-size: ${theme.FONT_SIZE_XXL};
    margin-top: ${columns(0.5)};
    margin-bottom: ${columns(0.5)};
  }
`

const Tagline = styled.h2`
  font-size: ${theme.FONT_SIZE_L};
  font-weight: normal;
  margin-bottom: 74px;
  ${stringStyle}

  ${MQ_DESKTOP} {
    max-width: ${columns(10)};
    font-size: ${theme.FONT_SIZE_XL};
  }
`

const Jumbotron = ({ comments, title, tagline, groupUrl }) => {
  return (
    <Container>
      {comments.map((c, i) => (
        <Comment key={i}>{c}</Comment>
      ))}
      <Title>{title}</Title>
      <Tagline>{tagline}</Tagline>
      <Button linkTo={groupUrl}>View on meetup.com</Button>
    </Container>
  )
}

Jumbotron.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
  groupUrl: PropTypes.string.isRequired,
}

export default Jumbotron
