import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { MQ_DESKTOP, columns } from "../../styles"
import theme from "../../styles/theme"

const Container = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 40px;

    ${MQ_DESKTOP} {
      margin-bottom: ${columns(0.5)};
    }
  }
`

const Image = styled(Img)`
  width: 40px;
  height: 40px;
  border-radius: 30px;
  margin-right: 20px;
  flex-shrink: 0;

  ${MQ_DESKTOP} {
    margin-right: ${columns(1)};
    height: 55px;
    width: 55px;
  }
`

const Email = styled.p`
  font-size: ${theme.FONT_SIZE_S};

  ${MQ_DESKTOP} {
    font-size: ${theme.FONT_SIZE_M};
  }
`

const Contact = ({ fluid, email }) => (
  <Container>
    <Image fluid={fluid} />
    <Email>{email}</Email>
  </Container>
)

Contact.propTypes = {
  fluid: PropTypes.any.isRequired,
  email: PropTypes.string.isRequired,
}

export default Contact
