import React from "react"
import styled from "@emotion/styled"
import { MQ_DESKTOP, columns, commentStyle, Comment } from "../styles"
import theme from "../styles/theme"

const CommentHeaderContainer = styled.div`
  margin-bottom: 60px;

  ${MQ_DESKTOP} {
    margin-bottom: ${columns(0.75)};
  }
`

const CommentHeaderText = styled.h4`
  font-size: ${theme.FONT_SIZE_S};
`

const SubHeader = styled.h5`
  font-size: ${theme.FONT_SIZE_S};
  margin-bottom: 32px;
  ${commentStyle}

  ${MQ_DESKTOP} {
    margin-bottom: ${columns(0.25)};
  }
`

export const CommentHeader = ({ children }) => (
  <CommentHeaderContainer>
    <Comment />
    <CommentHeaderText css={commentStyle}>{children}</CommentHeaderText>
    <Comment />
  </CommentHeaderContainer>
)

export const CommentSubHeader = ({ children }) => (
  <SubHeader>{children}</SubHeader>
)
